import React from 'react';

function BaseContainer({ children }) {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        backgroundColor: '#F2F2F2'
      }}
    >
      {children}
    </div>
  )
}

export default BaseContainer;
