import React, { useState, useRef, useEffect } from "react";
import { Button, Icon, Input } from "semantic-ui-react";

function InputPin({ submit, isLoading }) {
  const [values, setValues] = useState(["", "", "", ""]);
  const [isFilled, setIsFilled] = useState(false);
  const [inputedValue, setInputedValue] = useState("");
  // const inputRefs = []
  const inputRefs = useRef([]);

  useEffect(() => {
    // Check if all inputs are filled
    const allFilled = values.every((value) => value.length === 1);
    setIsFilled(allFilled);
  }, [values]);

  const handleChange = (index, value) => {
    const sanitizedValue = value.toUpperCase().replace(/[^A-Z0-9]/g, ""); // Convert to uppercase and remove non-alphanumeric characters

    const newValues = [...values];
    newValues[index] = sanitizedValue.slice(0, 1); // Limit to one character
    setValues(newValues);

    if (sanitizedValue.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleInputClick = (index) => {
    const newValues = [...values];
    newValues[index] = ""; // Clear the value of the clicked input
    setValues(newValues);
  };

  const handleBlur = (index) => {
    const newValues = [...values];
    if (newValues[index]) {
      // If input has a value, switch input type to password-like
      inputRefs.current[index].type = "password";
    }
  };

  function handleSubmit() {
    // const pin = values.join("");
    // submit(pin);
    submit(inputedValue)
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {/* {values.map((value, index) => (
        <Input
          key={index}
          type="text" // Changed type to text to allow for single character input
          maxLength={1} // Limit input to a single character
          value={value}
          onChange={(e) => handleChange(index, e.target.value)}
          onClick={() => handleInputClick(index)} // Clear input value when clicked
          onBlur={() => handleBlur(index)} // Hide input as password-like on blur if it has a value
          style={{ width: "50px", height: "50px", marginRight: "10px" }}
          ref={(ref) => (inputRefs.current[index] = ref)}
          disabled={isLoading}
        />
      ))} */}
      <Input
        type="password"
        maxLength={4}
        value={inputedValue}
        onChange={(e) => setInputedValue(e.target.value)}
        disabled={isLoading}
      />
      <Button
        primary
        onClick={() => handleSubmit()}
        disabled={inputedValue.length !== 4 || isLoading}
        floated="right"
        loading={isLoading}
      >
        <Icon style={{ marginLeft: -2 }} name="angle double right" />
      </Button>
    </div>
  );
}

export default InputPin;
