import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Button, Divider, Icon } from "semantic-ui-react";

function Dialog({ user, refreshDialog, dialogs, loading, setLoading }) {
  async function deleteMessage(id) {
    setLoading(true);

    try {
      const url = `https://admdan.com.br/api/log/?id=${id}`;
      // const url = `http://localhost:3001/core/log/?id=${id}`; // Adjust URL based on your backend API

      const response = await fetch(url, { method: "DELETE" });

      if (response.ok) {
        toast.success("Comentário removido com sucesso");
      } else {
        toast.error("Erro ao remover comentário");
        console.error("Error deleting LogVenda entry:", await response.text());
      }
    } catch (error) {
      toast.error("Erro ao remover comentário");
    } finally {
      setLoading(false);
      refreshDialog();
    }
  }

  useEffect(() => {
    refreshDialog();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: -18,
          minHeight: 360,
        }}
      >
        <i>Carregando mensagens...</i>
      </div>
    );
  }

  if (dialogs.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: -18,
          minHeight: 360,
          flexDirection: "column",
        }}
      >
        <i>Nenhuma mensagem</i>
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        overflowY: "scroll",
        height: 360,
        paddingRight: 12,
        marginBottom: -18,
      }}
    >
      {dialogs.map((dialog) => {
        return (
          <>
            <div
              style={{
                minHeight: 64,
                marginTop: 12,
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <b>{dialog.userName}</b>
                {user.isAdmin && (
                  <Button
                    floated="right"
                    icon={"trash"}
                    negative
                    size="mini"
                    style={{ opacity: 0.6 }}
                    circular
                    onClick={() => deleteMessage(dialog.id)}
                  />
                )}
              </div>
              <p>{dialog.comment + " "}</p>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <p>
                  {new Date(dialog.createdAt).toLocaleDateString() + " - "}
                  {new Date(dialog.createdAt).toLocaleTimeString()}
                </p>
              </div>
            </div>
            <Divider />
          </>
        );
      })}
    </div>
  );
}

export default Dialog;
