import React, { useEffect, useState } from "react";
import { Flip, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Loader } from "semantic-ui-react";
import BaseContainer from "./components/BaseContainer";
import Header from "./components/Header";
import VersionBadge from "./components/VersionBadge";
import Auth from "./pages/auth";
import List from "./pages/list";

const version = "0.3.3";

function App() {
  const [user, setUser] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchUserFromLocalStorage();
  }, []);

  const fetchUserFromLocalStorage = async () => {
    const encodedUser = localStorage.getItem("user");

    if (encodedUser) {
      try {
        const decodedUser = atob(encodedUser);

        const userPin = JSON.parse(decodedUser).pin;

        const response = await fetch("https://admdan.com.br/api/users/login", {
          // const response = await fetch("http://localhost:3001/core/users/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pin: userPin }),
        });

        if (response.ok) {
          const responseData = await response.text();

          if (encodedUser === responseData) {
            const userData = JSON.parse(decodedUser);

            toast.info("Bem vindo, " + userData.name);

            setUser(userData);
          }
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    } else {
      setUser(undefined);
    }

    setIsLoading(false);
  };

  function onSignOut() {
    setTimeout(() => {
      toast("Volte sempre!");
    }, 100);
    setIsLoading(true);
    setUser(undefined);
    localStorage.removeItem("user");
    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <BaseContainer>
        <VersionBadge version={version} />
        <ToastContainer
          closeOnClick
          newestOnTop
          position="bottom-right"
          hideProgressBar
          draggable
          autoClose={1000}
          transition={Flip}
        />
        <Loader active size="huge" inline="centered"></Loader>
      </BaseContainer>
    );
  }

  if (user === undefined) {
    return (
      <>
        <VersionBadge version={version} />
        <Auth setUser={setUser} />
        <ToastContainer
          closeOnClick
          newestOnTop
          position="bottom-right"
          hideProgressBar
          transition={Flip}
          autoClose={1000}
          draggable
        />
      </>
    );
  }

  return (
    <>
      <ToastContainer
        closeOnClick
        newestOnTop
        position="bottom-right"
        hideProgressBar
        autoClose={1000}
        draggable
        transition={Flip}
      />
      <BaseContainer>
        <VersionBadge version={version} />
        <Header onSignOut={onSignOut} user={user} />
        <List user={user} />
      </BaseContainer>
    </>
  );
}

export default App;
