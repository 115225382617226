import React from "react";
import { Container, Grid, Input, ModalContent } from "semantic-ui-react";

function ModalAddItem(props) {
  const { formData, handleInputChange, isLoading } = props;

  return (
    <ModalContent>
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column computer={4}>
              <Input
                name="plate"
                placeholder="Placa"
                fluid
                value={formData.plate}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </Grid.Column>
            <Grid.Column computer={4}>
              <Input
                name="vehicle"
                placeholder="Veículo"
                fluid
                value={formData.vehicle}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </Grid.Column>
            <Grid.Column computer={4}>
              <Input
                name="seller"
                placeholder="Vendedor"
                fluid
                value={formData.seller}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </Grid.Column>
            <Grid.Column computer={4}>
              <Input
                name="saleDate"
                placeholder="Data da venda"
                type="date"
                fluid
                value={formData.saleDate}
                onChange={handleInputChange}
                disabled={isLoading}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </ModalContent>
  );
}

export default ModalAddItem;
