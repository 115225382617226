import React from "react";
import BaseContainer from "../components/BaseContainer";
import InputPin from "../components/InputPin.";
import { toast } from "react-toastify";

function Auth(props) {
  const { setUser } = props;

  const [isLoading, setIsLoading] = React.useState(false);

  async function handleOnClick(pin) {
    const data = await authenticateUser(pin);
    setUser(data);
  }

  const authenticateUser = async (pin) => {
    setIsLoading(true);
    try {
      // const response = await fetch("http://localhost:3001/core/users/login", {
        const response = await fetch("https://admdan.com.br/api/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pin }),
      });

      if (!response.ok) {
        toast.error("Erro ao autenticar usuário");
        throw new Error("Failed to authenticate user");
      }

      const encodedData = await response.text();
      localStorage.setItem("user", encodedData);

      const decodedData = atob(encodedData);
      const userData = JSON.parse(decodedData);
      setIsLoading(false);

      return userData;
    } catch (error) {
      toast.error("PIN incorreto", error);

      setIsLoading(false);
    }
  };

  return (
    <BaseContainer>
      <InputPin submit={handleOnClick} isLoading={isLoading} />
    </BaseContainer>
  );
}

export default Auth;
