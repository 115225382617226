import React from "react";
import { Card, Icon, Loader, Popup } from "semantic-ui-react";

const emPautaColor = "#87D880";
const vencidoColor = "#FF6C6C";
const atrasadoColor = "#FFCE6E";
const finalizadoColor = "#53B5FF";
const guiaPagaColor = "#FFA4F0";

function ListRender(props) {
  const { openModal, isLoading, salesData } = props;

  function handleCardColor(item) {
    const diffInDays = Math.floor(
      (new Date() - new Date(item.data)) / (1000 * 60 * 60 * 24)
    );

    if (item.finalizado) {
      return finalizadoColor;
    } else if (item.guiaPaga) {
      return guiaPagaColor;
    } else if (diffInDays < 30) {
      return emPautaColor;
    } else if (diffInDays >= 30 && diffInDays < 90) {
      return atrasadoColor;
    } else {
      return vencidoColor;
    }
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: "1rem",
      }}
    >
      {isLoading ? (
        <Loader active size="huge" />
      ) : (
        <Card.Group centered>
          <Popup
            content="Adicionar venda"
            position="bottom center"
            trigger={
              <Card
                raised
                style={{
                  color: "#C8C8C8",
                  maxWidth: 240,
                  height: 100,
                }}
                onClick={() => openModal()}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Icon name="plus" size="small" />
                </div>
              </Card>
            }
          />

          {/* {salesData.length === 0 && (
            <Header as="h3" textAlign="center">
              Nenhuma venda cadastrada
            </Header>
          )} */}

          {salesData.map((item, index) => {
            return (
              <Popup
                key={index + item.veiculo}
                content={
                  <>
                    <p>Vendedor: {item.vendedor}</p>
                    <p>
                      Data da venda:{" "}
                      {new Date(item.data).toLocaleDateString() || "--/--/----"}
                    </p>
                  </>
                }
                position="bottom center"
                trigger={
                  <Card
                    raised
                    style={{
                      backgroundColor: handleCardColor(item),
                      color: "#FFFF",
                      maxWidth: 240,
                      height: 100,
                    }}
                    onClick={() => openModal(item, index)}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        flexDirection: "column",
                        gap: 12,
                      }}
                    >
                      <span style={{ fontSize: 18, fontWeight: "700" }}>
                        {item.placa}
                      </span>
                      <span style={{ fontSize: 10, fontWeight: "700" }}>
                        {item.veiculo}
                      </span>
                    </div>
                  </Card>
                }
              />
            );
          })}
        </Card.Group>
      )}
    </div>
  );
}

export default ListRender;
