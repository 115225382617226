import React from "react";
import { Icon, Image, Menu, MenuItem } from "semantic-ui-react";

const image = require("../assets/logo.png");

function Header(props) {
  const { onSignOut, user } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        position: "fixed",
        top: 0,
      }}
    >
      <Menu
        style={{ width: "100%", backgroundColor: "#E7E7E7" }}
        borderless
        secondary
        fluid
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <MenuItem>
            <Image src={image} size="small" />
          </MenuItem>

          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* <MenuItem

            onClick={() => { }}
          >
            <Icon name="users" />
          </MenuItem> */}

            {user && user.name && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  
                }}
              >
                <p>Olá, {user.name}</p>
              </div>
            )}

            <MenuItem color="red" onClick={() => onSignOut(undefined)}>
              <Icon color="red" name="sign-out" size="large" />
            </MenuItem>
          </div>
        </div>
      </Menu>
    </div>
  );
}

export default Header;
