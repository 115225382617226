import React from "react";
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Input,
  Button,
} from "semantic-ui-react";
import ListRender from "../components/ListRender";
import ModalItem from "../components/ModalItem";
import { toast } from "react-toastify";

const options = [
  { key: "emPauta", text: "Em Pauta", value: "emPauta" },
  { key: "atrasado", text: "Atrasado", value: "atrasado" },
  { key: "vencido", text: "Vencido", value: "vencido" },
  { key: "guiaPaga", text: "Guia Paga", value: "guiaPaga" },
  { key: "finalizado", text: "Finalizado", value: "finalizado" },
];

function List(props) {
  const { user } = props;

  const [searchValue, setSearchValue] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState(undefined);
  const [modalVisibility, setModalVisibility] = React.useState(false);
  const [salesData, setSalesData] = React.useState([]);
  const [filteredSalesData, setFilteredSalesData] = React.useState([]);
  const [isLoadingSalesData, setIsLoadingSalesData] = React.useState(false);
  const [initialDate, setInitialDate] = React.useState(null);
  const [finalDate, setFinalDate] = React.useState(null);
  const [statusFilter, setStatusFilter] = React.useState([]);

  function handleSelectItem(item) {
    setSelectedItem(item);
    setModalVisibility(true);
  }

  function handleCloseModal() {
    setModalVisibility(false);
    setSelectedItem(undefined);
  }

  React.useEffect(() => {
    fetchSalesData();
  }, []);

  async function fetchSalesData() {
    setIsLoadingSalesData(true);
    try {
      // const response = await fetch("http://localhost:3001/core/venda", {
      const response = await fetch("https://admdan.com.br/api/venda", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        toast.error("Erro ao autenticar usuário");
        throw new Error("Failed to authenticate user");
      }

      const data = await response.json();
      setIsLoadingSalesData(false);
      setSalesData(data);
    } catch (error) {
      toast.error("Erro ao solicitar vendas");
    }
  }

  function filterItems() {
    let filteredItems = [];

    if (initialDate) {
      salesData.forEach((item) => {
        if (new Date(item.data) >= new Date(initialDate)) {
          filteredItems.push(item);
        }
      });
    }
    if (finalDate) {
      salesData.forEach((item) => {
        if (new Date(item.data) <= new Date(finalDate)) {
          filteredItems.push(item);
        }
      });
    }
    if (searchValue) {
      salesData.forEach((item) => {
        const lowercasePlaca = item.placa.toLowerCase();
        const lowercaseVeiculo = item.veiculo.toLowerCase();
        const lowercaseSearchValue = searchValue.toLowerCase();

        if (
          lowercasePlaca.includes(lowercaseSearchValue) ||
          lowercaseVeiculo.includes(lowercaseSearchValue)
        ) {
          filteredItems.push(item);
        }
      });
    }

    if (statusFilter.length > 0) {
    }

    const uniqueItems = [];
    filteredItems.forEach((item) => {
      if (!uniqueItems.some((i) => i.id === item.id)) {
        uniqueItems.push(item);
      }
    });

    setFilteredSalesData(uniqueItems);
  }

  return (
    <div style={styles.container}>
      <Container fluid>
        <Grid doubling>
          <Grid.Row>
            <Grid.Column computer={4} mobile={16} tablet={6}>
              <Input
                placeholder="Buscar"
                fluid
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value || "");
                }}
              />
            </Grid.Column>

            <Grid.Column computer={3} mobile={16} tablet={5}>
              <Input
                label="Inicial"
                type="date"
                fluid
                value={initialDate}
                onChange={(e) => setInitialDate(e.target.value)}
              />
            </Grid.Column>

            <Grid.Column computer={3} mobile={16} tablet={5}>
              <Input
                label="Final"
                type="date"
                fluid
                value={finalDate}
                onChange={(e) => setFinalDate(e.target.value)}
              />
            </Grid.Column>

            <Grid.Column computer={4} mobile={16} tablet={16}>
              {/* <Dropdown
                placeholder="Filtrar por status"
                multiple
                selection
                options={options}
                fluid
                value={statusFilter}
                onChange={(e, { value }) => setStatusFilter(value)}
              /> */}
            </Grid.Column>

            <Grid.Column
              computer={1}
              mobile={8}
              tablet={8}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <Button
                onClick={() => {
                  filterItems();
                }}
                primary
                type="submit"
                icon="search"
              >
                Buscar
              </Button>
              <Button
                onClick={() => {
                  setFilteredSalesData(salesData);
                  setFinalDate(null);
                  setInitialDate(null);
                  setSearchValue(null);
                  setStatusFilter([]);
                }}
                disabled={
                  !finalDate &&
                  !initialDate &&
                  !searchValue &&
                  statusFilter.length === 0
                }
                negative
              >
                Limpar
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      <Divider horizontal />

      <ListRender
        openModal={handleSelectItem}
        salesData={filteredSalesData.length > 0 ? filteredSalesData : salesData}
        isLoading={isLoadingSalesData}
      />

      <ModalItem
        item={selectedItem}
        visibility={modalVisibility}
        close={handleCloseModal}
        user={user}
        fetchSalesData={fetchSalesData}
      />
    </div>
  );
}

export default List;

const styles = {
  container: {
    width: "100%",
    alignSelf: "flex-start",
    marginTop: 84,
    marginLeft: 12,
    marginRight: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};
