import React from "react";
import { toast } from "react-toastify";
import {
  Button,
  Confirm,
  Container,
  Divider,
  Grid,
  Icon,
  Input,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "semantic-ui-react";
import Dialog from "./Dialog";
import ModalAddItem from "./ModalAddItem";

function ModalItem(props) {
  const { close, open, visibility, item, user, fetchSalesData } = props;

  const [isEditing, setIsEditing] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [confirmDeletion, setConfirmDeletion] = React.useState(false);
  const [allowEdit, setAllowEdit] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [dialogs, setDialogs] = React.useState([]);

  const [formData, setFormData] = React.useState({
    plate: "",
    vehicle: "",
    seller: "",
    saleDate: "",
  });

  const handleInputChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  const closeAndReset = () => {
    clearFormData();
    close();
  };

  async function refreshDialog() {
    setIsLoading(true);

    try {
      // const response = await fetch(
      // `http://localhost:3001/core/log/?idVenda=${item.id}`,
      // {
      const response = await fetch(
        `https://admdan.com.br/api/log/?idVenda=${item.id}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );

      const data = await response.json();

      setDialogs(data);
    } catch (error) {
      toast.error("Erro ao solicitar comentários");
    } finally {
      setIsLoading(false);
    }
  }

  async function addComment() {
    if (comment.length === 0) return;

    const body = {
      idVenda: item.id,
      comment: comment,
      userName: user.name,
      userId: user.id,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    // await fetch("http://localhost:3001/core/log/", options)
    await fetch("https://admdan.com.br/api/log/", options)
      .then(() => refreshDialog())
      .catch((err) => console.error(err))
      .finally(() => setComment(""));
  }

  async function submit() {
    setIsLoading(true);

    const payload = {
      placa: formData.plate,
      veiculo: formData.vehicle,
      vendedor: formData.seller,
      data: formData.saleDate,
      createdBy: user.id,
    };

    try {
      // const response = await fetch("http://localhost:3001/core/venda", {
      const response = await fetch("https://admdan.com.br/api/venda", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to authenticate user");
      }

      const data = await response.json();
      await fetchSalesData();

      setIsLoading(false);
      toast.success("Adicionado com sucesso!");
      closeAndReset();
      return data;
    } catch (error) {
      toast.error("Erro ao adicionar venda");
      console.error("");
    }
  }

  function clearFormData() {
    setFormData({});
  }

  async function handleUpdate(item, type) {
    setIsLoading(true);

    let updatedItem;

    if (type) {
      updatedItem = {
        id: item.id,
        guiaPaga: type === "guiaPaga",
        finalizado: type === "finalizado",
      };
    } else {
      updatedItem = {
        id: item.id,
        placa: formData.plate,
        veiculo: formData.vehicle,
        vendedor: formData.seller,
        data: formData.saleDate,
        createdBy: user.id,
      };

      setAllowEdit(false);
    }

    try {
      // const response = await fetch(`http://localhost:3001/core/venda`, {
      const response = await fetch(`https://admdan.com.br/api/venda`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedItem),
      });

      if (!response.ok) {
        throw new Error("Failed to update item");
      }

      await fetchSalesData();
      toast.success("Atualizado com sucesso!");
      closeAndReset();
      setIsLoading(false);
      return;
    } catch (error) {
      console.error("Error updating item:", error);
      toast.error("Erro ao atualizar venda");
    }
  }

  async function handleDelete(itemId) {
    setIsLoading(true);
    try {
      const response = await fetch(`https://admdan.com.br/api/venda`, {
        // const response = await fetch(`http://localhost:3001/core/venda`, {
        method: "DELETE", // Use DELETE method for deleting a resource
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: itemId,
          deletedBy: user.id,
          placa: item.placa,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to delete item");
      }

      await fetchSalesData();
      setConfirmDeletion(false);
      setIsLoading(false);
      toast.success("Removido com sucesso!");
      closeAndReset();
    } catch (error) {
      toast.error("Erro ao remover venda");
      console.error("Error deleting item:", error);
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    if (item) {
      const formattedDate = item
        ? new Date(item.data).toISOString().substr(0, 10)
        : "";

      const newItem = {
        plate: item.placa,
        vehicle: item.veiculo,
        seller: item.vendedor,
        saleDate: formattedDate,
      };

      setFormData(newItem);
    } else {
      setFormData({
        plate: "",
        vehicle: "",
        seller: "",
        saleDate: "",
      });
    }
  }, [item]);

  React.useEffect(() => {
    if (item) setIsEditing(item);
    else setIsEditing(false);
  }, [item]);

  return (
    <Modal
      onClose={closeAndReset}
      onOpen={open}
      open={visibility}
      dimmer="blurring"
      closeIcon={<Icon name="close" />}
    >
      <ModalHeader>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {isEditing ? "Visualizar Venda" : "Adicionar Venda"}
          <div>
            {isEditing && user.isAdmin && item?.createdBy !== "admycar" && (
              <Button
                floated="right"
                icon={allowEdit ? "check" : "edit"}
                positive={allowEdit}
                loading={isLoading}
                circular
                onClick={() =>
                  allowEdit ? handleUpdate(item) : setAllowEdit(true)
                }
              />
            )}
            <Button
              icon={"refresh"}
              loading={isLoading}
              circular
              onClick={() => refreshDialog()}
            />
          </div>
        </div>
      </ModalHeader>

      {isEditing ? (
        <ModalContent scrolling>
          <Container>
            <Grid
              style={{
                // height: 120,
                // marginBottom: 4,
                // position: "fixed",
                zIndex: 1,
                // backgroundColor: "#e0e1e2",
                // width: "calc(100% - 48px)",
                // marginTop: -35,
                // boxShadow: "0 5px 5px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Grid.Row>
                <Grid.Column computer={4}>
                  <Input
                    label="Placa"
                    fluid
                    name="plate"
                    value={formData.plate}
                    onChange={(e) =>
                      allowEdit
                        ? handleInputChange(e, {
                            name: e.target.name,
                            value: e.target.value,
                          })
                        : null
                    }
                  />
                </Grid.Column>
                <Grid.Column computer={12}>
                  <Input
                    label="Veículo"
                    fluid
                    name="vehicle"
                    value={formData.vehicle}
                    onChange={(e) =>
                      allowEdit
                        ? handleInputChange(e, {
                            name: e.target.name,
                            value: e.target.value,
                          })
                        : null
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column computer={11}>
                  <Input
                    label="Vendedor"
                    name="seller"
                    fluid
                    value={formData.seller}
                    onChange={(e) =>
                      allowEdit
                        ? handleInputChange(e, {
                            name: e.target.name,
                            value: e.target.value,
                          })
                        : null
                    }
                  />
                </Grid.Column>
                <Grid.Column computer={5}>
                  <Input
                    label="Data da venda"
                    type="date"
                    fluid
                    name="saleDate"
                    value={formData.saleDate}
                    onChange={(e) =>
                      allowEdit
                        ? handleInputChange(e, {
                            name: e.target.name,
                            value: e.target.value,
                          })
                        : null
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Divider />

            {item && (
              <Dialog
                user={user}
                refreshDialog={refreshDialog}
                dialogs={dialogs}
                loading={isLoading}
                setLoading={setIsLoading}
              />
            )}
          </Container>
        </ModalContent>
      ) : (
        <ModalAddItem
          formData={formData}
          handleInputChange={handleInputChange}
          isLoading={isLoading}
        />
      )}
      {isEditing ? (
        <ModalActions style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              height: 60,
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Input
              fluid
              style={{ width: "100%" }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              disabled={isLoading}
              action={
                <Button onClick={() => addComment()} disabled={isLoading}>
                  <Icon name="send" style={{ marginLeft: 2 }} />
                </Button>
              }
            />
          </div>

          <Divider />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {item?.createdBy !== "admycar" && user.isAdmin && (
                <>
                  <Button
                    content="Excluir"
                    basic
                    onClick={() => setConfirmDeletion(true)}
                    style={{ marginRight: 8 }}
                    negative
                    disabled={allowEdit}
                    loading={isLoading}
                  />

                  <Confirm
                    open={confirmDeletion}
                    onCancel={() => setConfirmDeletion(false)}
                    onConfirm={() => handleDelete(item.id)}
                    content="Tem certeza que deseja excluir?"
                    dimmer
                  />
                </>
              )}
            </div>
            <div>
              {!item?.guiaPaga && !item?.finalizado && (
                <Button
                  content="Guia paga"
                  labelPosition="right"
                  icon="dollar"
                  onClick={() => handleUpdate(item, "guiaPaga")}
                  color="pink"
                  loading={isLoading}
                  disabled={allowEdit}
                  style={{ marginRight: 8 }}
                />
              )}
              {!item?.finalizado && item?.guiaPaga && (
                <Button
                  content="Finalizar"
                  labelPosition="right"
                  loading={isLoading}
                  icon="check"
                  disabled={allowEdit}
                  onClick={() => handleUpdate(item, "finalizado")}
                  primary
                />
              )}
            </div>
          </div>
        </ModalActions>
      ) : (
        <ModalActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            color="black"
            disabled={allowEdit}
            onClick={() => closeAndReset()}
            loading={isLoading}
          >
            Cancelar
          </Button>

          <Button
            content="Salvar"
            labelPosition="right"
            icon="save"
            onClick={() => submit()}
            positive
            disabled={allowEdit}
            loading={isLoading}
          />
        </ModalActions>
      )}
    </Modal>
  );
}

export default ModalItem;
