import React from "react";

function VersionBadge({ version }) {
  return (
    <span
      style={{
        position: "fixed",
        bottom: 4,
        left: 4,
        color: "white",
        backgroundColor: "#00000033",
        padding: "5px 10px",
        borderRadius: "5px",
      }}
    >
      v{version}
    </span>
  );
}

export default VersionBadge;
